import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import '../../styles/cards.css';
import { IoMdHeart } from "react-icons/io";
import { Link } from 'react-router-dom';
import { IoBagHandleOutline } from "react-icons/io5";
import { useEffect, useState } from 'react';
import { IoMdClose } from "react-icons/io";


export const OrderCard = ({ 
	order,
	render,
	setRender,
}) => {

	const [datePublishPost, setDatePublishPost] = useState(null);
	const [step, setStep] = useState(1);

	function getMonthName(monthNumber) {
		const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
		return monthNames[monthNumber];
	}

	function formatDate(dateString) {
		const date = new Date(dateString);
		const day = date.getUTCDate();
		const month = date.getUTCMonth();
		const year = date.getUTCFullYear().toString();

		const monthName = getMonthName(month);
		return `${day} de ${monthName}, ${year}`;
	}

	useEffect(() => {
		if (order && order.date_issued) {
			setDatePublishPost(formatDate(order.date_issued))
		}
		if (order && order.status) {
	      setStep(stepMap[order.status]);
	      console.log(step)
	    }
	}, [order]);

	const statusMap = {
  		sin_procesar: 'Sin procesar',
  		procesado: 'Procesado',
  		en_camino: 'En camino',
  		entregado: 'Entregado',
  		cancelado: 'Cancelado'
	};

	const stepMap = {
  		sin_procesar: 1,
  		procesado: 2,
  		en_camino: 3,
  		entregado: 4,
  		cancelado: 0
	};

	function classNames(...classes) {
		return classes.filter(Boolean).join(' ')
	}

	return (
		<div className='container-order-product-card'>
			<div className='container-header-order-card'>
				<h3>Detalles del pedido</h3>
				<div className='contain-header-order-card'>
					<div className='container-number-order'>
						<div className='contain-transaction-id'>
						 	<p className='transaction-title'>Número de orden:</p>
						 	<p className='transaction-id-text'>{order.transaction_id}</p>
						</div>
						<p className='date-text'>{datePublishPost}</p>
					</div>
					<Link></Link>
				</div>
			</div>
			<div className='contain-transaction-id'>
				<p className='transaction-title'>ID de la transacción:</p>
				<p className='transaction-id-text'>{order.transaction_id}</p>
			</div>
			<div className='container-addres-info'>
				<div className='container-addres-text'>
					<h4>Dirección de envio</h4>
					<div className='contain-addres-text'>
						<p>{order.address_line_1}</p>
						<p>{order.address_line_2}</p>
					</div>
				</div>
				<div className='container-addres-text'>
					<h4>Costo</h4>
					<div className='contain-addres-text'>
						<p>$ {order.shipping_price}</p>
					</div>
				</div>
			</div>
			<div className='container-order-product'>
				<div className='order-status-date'>
					<p>Estado: <span>{order && statusMap[order.status]}</span></p>
					<p className='date-text'>Comprado en: <span>{datePublishPost}</span></p>
				</div>
                <div className='order-progres-bar'>
                    <div className='order-progres-bar-inner' style={{ '--step': `${step}` }}></div>
                </div>
				<div className='container-text-bar-progress'>
					<div className={`text-bar-progress ${(step === 1 || step === 2) ? 'active-text' : ''}`}>Sin procesar</div>
					<div className={`text-bar-progress ${(step === 2 || step === 3) ? 'active-text' : ''}`}>Procesado</div>
					<div className={`text-bar-progress ${(step === 3 || step === 4) ? 'active-text' : ''}`}>En camino</div>
					<div className={`text-bar-progress ${(step === 4) ? 'active-text' : ''}`}>Entregado</div>
				</div>
			</div>
		</div>
	)
};