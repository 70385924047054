import React from 'react';

export const GoogleMapsEmbed = () => {
    return (
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3408.0478214886148!2d-63.62765232362799!3d-31.330052392084365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432dfab06e1db05%3A0x83bc46204ce9d86a!2sJoyeria%20-%20Relojeria%20La%20Joyita!5e0!3m2!1ses-419!2sar!4v1721911651389!5m2!1ses-419!2sar"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps"
        ></iframe>
    );
};
